import React from "react";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import { compose, lifecycle, withStateHandlers } from "recompose";
import { Form, Field } from "react-final-form";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Grid";
import TextField from "../../../forms/TextField";
import Timer from "../../../widgets/Timer";
import { NumberOfTry } from "../../../utils";
import { withStyles } from "@material-ui/core";
import { Spacer } from "../../../widgets/Spacer";
import "../QrCodeGenerator/index.css";

const styles = () => ({
  terms: {
    textDecoration: "underline",
    cursor: "pointer",
  },
});
const Component = ({
  actions,
  loginInformation,
  responseError,
  sessionInformation,
  saveResponse,
}) => {
  const onSubmit = (values, form) => {
    actions.checkChallenge(
      loginInformation.context.challengeId,
      loginInformation.context.userId,
      values.response
    );
    saveResponse(values.response);
    setTimeout(() => {
      form.reset();
    }, 500);
    if (loginInformation) {
      if (loginInformation.context.countAttemps === 0) {
        loginInformation.context.countAttemps = 1;
        window.location.href = "/qr-code?redirectTo=/admin";
      }
      if (loginInformation.context.countAttemps > 0) {
        loginInformation.context.countAttemps =
          loginInformation.context.countAttemps - 1;
      }
    }
  };
  const endTimer = (value) => {
    const { history, onClose } = this.props;
    if (value) {
      onClose(true);
      history.push("/");
    }
  };
  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <h3 style={{ textAlign: "center" }}>
          {`Hello ${sessionInformation.firstName} ${sessionInformation.lastName}`}
        </h3>
      </Grid>
      <Grid item xs={12}>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, pristine, invalid }) => (
            <form onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container justify="center">
                    <Timer
                      end={(value) => endTimer(value)}
                      duration={loginInformation.context.timer}
                    />
                  </Grid>
                </Grid>
                <div
                  style={{
                    marginBottom: 20,
                    display: "block",
                    width: "100%",
                  }}
                ></div>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  {responseError ? (
                    <Grid
                      container
                      style={{ paddingLeft: 70, paddingRight: 70 }}
                    >
                      <Grid item xs={12}>
                        <Grid container></Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          {loginInformation.challengeReceptionMode !==
                          "APPS" ? (
                            <>
                              <Typography
                                variant="body2"
                                style={{
                                  color: "#28558a",
                                  fontWeight: 600,
                                }}
                              >
                                <FormattedMessage id="challenge.message.one" />
                              </Typography>
                              <span id="hia_challenge">
                                {sessionStorage.getItem("Challenge")}
                              </span>
                            </>
                          ) : (
                            <Typography
                              variant="body2"
                              style={{
                                color: "#28558a",
                                fontWeight: 600,
                              }}
                            >
                              <FormattedMessage id="challenge.message.app" />
                            </Typography>
                          )}
                        </Grid>
                        &nbsp;
                        <Grid item xs={12}>
                          <Grid container>
                            <Typography
                              variant="body2"
                              style={{
                                color: "#28558a",
                                fontWeight: 600,
                              }}
                            >
                              <FormattedMessage id="challenge.message.two" />
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      &nbsp;
                    </Grid>
                  ) : (
                    <Grid
                      container
                      style={{ paddingLeft: 70, paddingRight: 70 }}
                    >
                      <Grid item xs={12}>
                        <Grid container>
                          {loginInformation.challengeReceptionMode !==
                          "APPS" ? (
                            <>
                              <Typography
                                variant="body2"
                                style={{
                                  color: "#28558a",
                                  fontWeight: 600,
                                }}
                              >
                                <FormattedMessage id="challenge.message.one" />
                              </Typography>
                              <span id="hia_challenge">
                                {sessionStorage.getItem("Challenge")}
                              </span>
                            </>
                          ) : (
                            <Typography
                              variant="body2"
                              style={{
                                color: "#28558a",
                                fontWeight: 600,
                              }}
                            >
                              <FormattedMessage id="challenge.message.app" />
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      &nbsp;
                      <Grid item xs={12}>
                        <Grid container>
                          <Typography
                            variant="body2"
                            style={{
                              color: "#28558a",
                              fontWeight: 600,
                            }}
                          >
                            <FormattedMessage id="challenge.message.two" />
                          </Typography>
                        </Grid>
                      </Grid>
                      &nbsp;
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  item
                  xs={12}
                  style={{ paddingLeft: 70, paddingRight: 70 }}
                >
                  <Grid container>
                    <Typography
                      variant="body2"
                      style={{ color: "#28558a", fontWeight: 600 }}
                    >
                      <FormattedMessage id="challenge.message.tree" />
                    </Typography>
                  </Grid>
                  <Field
                    component={TextField}
                    //validate={required('required')}
                    name="response"
                    type="text"
                    label="Response"
                    autoFocus
                    margin="dense"
                    variant="outlined"
                    fullWidth
                  />
                  &nbsp;
                </Grid>

                {responseError && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingLeft: 70,
                      paddingRight: 70,
                      marginBottom: 10,
                    }}
                  >
                    <Grid container>
                      <Typography variant="body2" style={{ color: "red" }}>
                        <FormattedMessage id="challenge.invalidanswer" />
                      </Typography>
                      <Spacer size="SMALL" />-<Spacer size="SMALL" />
                      <Typography variant="body2" style={{ color: "red" }}>
                        <FormattedMessage
                          id={NumberOfTry(
                            loginInformation.context.countAttemps
                          )}
                          values={{
                            attempts: loginInformation.context.countAttemps,
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Grid container justify="center">
                    <Button
                      type="submit"
                      color="primary"
                      disabled={pristine || invalid}
                    >
                      OK
                    </Button>
                  </Grid>
                  &nbsp;
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    justify="flex-start"
                    style={{ paddingLeft: 70, paddingRight: 0 }}
                  >
                    <Grid item xs={12}>
                      <Grid container justify="flex-start">
                        <Typography variant="body2">
                          <FormattedMessage id="contact.challenge.issue" />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </Grid>
    </Grid>
  );
};

const loadComponents = lifecycle({
  componentDidMount() {
    sessionStorage.removeItem("Challenge");
    const { actions, loginInformation } = this.props;
    console.log("login information : ", loginInformation);
  },
  componentWillReceiveProps(nextProps) {
    const { challengeInformation, history, onClose } = this.props;

    if (nextProps.challengeInformation !== challengeInformation) {
      if (!nextProps.responseError) {
        sessionStorage.setItem(
          "Challenge",
          nextProps.challengeInformation.challenge
        );
      }
    }
    if (nextProps.isLoggedIn === true) {
      onClose(true);
      history.push("/admin");
    }
  },
});

const withResponse = withStateHandlers(() => ({ response: undefined }), {
  saveResponse: () => (value) => ({ response: value }),
});

export const enhance = compose(
  withStyles(styles),
  withResponse,
  withRouter,
  loadComponents
);
export default enhance(Component);
